// Production User Pool

// export default {
//     "Auth": {
//         "identityPoolId":"us-east-1:22dea456-239d-477c-bc59-f202270ccc6c",
//         "region":"us-east-1",
//         "userPoolId":"us-east-1_w3ffgpcPW",
//         "userPoolWebClientId":"1qmmv9rnli6j7dfiqjub0q4e1a"
//     }
// }


// Development User Pool

export default {
    "Auth": {
        "identityPoolId":"us-east-1:7eee43fb-ff2c-4735-9a7a-449698651ee6",
        "region":"us-east-1",
        "userPoolId":"us-east-1_PW8SKcYdI",
        "userPoolWebClientId":"pjapfp7k90gihaki9s9red0e8"
    }
}